<template>
  <div v-if="!isLoading">
    <b-modal id="modal-edit-tags" :title="textModal" body-class="p-0" centered>
      <div class="p-3">
        <div>
          <b-form-tags
            class="input-tags"
            input-id="tags"
            separator="  "
            placeholder="Add Tag"
            v-model="data"
            @input.native="onInput($event)"
            @keyup.enter="onEnter($event)"
          ></b-form-tags>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-button
            class="btn-cancel px-4 mx-2"
            size="sm"
            @click="$bvModal.hide('modal-edit-tags')"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right px-4"
            :disabled="!check"
            @click="handleSubmit()"
          >
            Confirm
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalLoading from "../modal/ModalLoading.vue";

export default {
  components: {
    ModalLoading
  },
  props: {
    userChatInfo: {
      type: Object,
      required: false
    },
    tags: {
      type: [Array, Object],
      required: false
    },
    crmDetail: {
      type: Object,
      required: false
    },
    textModal: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      data: [],
      isLoading: true,
      value: "",
      check: true
    };
  },
  watch: {
    data: function() {
      this.check = true;
    }
  },
  mounted() {
    setTimeout(() => {
      this.setTag();
    }, 500);
  },
  methods: {
    onInput($event) {
      this.value = $event.target.value;
      if (!$event.target.value) this.check = true;
      else this.check = false;
    },
    onEnter() {
      if (!this.data.includes(this.value)) {
        this.check = true;
      }
    },
    async handleSubmit() {
      if (!this.check) return;
      this.$refs.modalLoading.show();
      await this.handleTag();
      let body = {
        userID: this.userChatInfo.externalId,
        crmUserId: this.crmDetail.user_guid,
        brandId: this.$route.query.brandID,
        tags: this.data
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/crm/edittag`,
        body
      );
      this.$refs.modalLoading.hide();

      if (response.data.result) {
        this.$emit("get-detail");
        this.$store.commit("setMessageGlobalAlert", "Edit Tags Success");
        this.$store.commit("setShowGlobalAlert", true);
        this.hide();
      } else {
        this.$store.commit("setMessageGlobalAlertError", response.data.detail);
        this.$store.commit("setShowGlobalAlertError", true);
      }
    },
    setTag() {
      this.isLoading = true;
      this.data = [];
      if (this.tags && this.tags.length > 0) {
        this.tags.map(x => this.data.push(x.name));
      }
      this.isLoading = false;
    },
    async handleTag() {
      for (let index = 0; index < this.data.length; index++) {
        let indexTag = this.tags.findIndex(t => t.name == this.data[index]);
        if (indexTag != -1) this.data[index] = this.tags[indexTag];
        else {
          if (this.data[index].id == undefined) {
            this.data[index] = {
              id: 0,
              name: this.data[index]
            };
          }
        }
      }
    },
    show() {
      this.$bvModal.show("modal-edit-tags");
    },
    hide() {
      this.$bvModal.hide("modal-edit-tags");
    }
  }
};
</script>

<style scoped>
::v-deep #tags {
  margin-left: 5px !important;
}

::v-deep .h-auto {
  height: 100px !important;
  overflow: auto;
}

::v-deep .text-truncate {
  margin-right: 5px;
  margin-left: 2px;
  font-size: 12px !important;
}

::v-deep .b-form-tags-list .btn {
  color: #fff;
  background-color: #321fdb;
  border-color: #321fdb;
}

::v-deep .align-items-baseline {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgba(113, 96, 196, 0.27);
  border-radius: 2px;
  color: rgb(113, 96, 196);
  cursor: pointer;
}
</style>
