<template>
  <b-modal
    hide-footer
    v-model="showModal"
    size="md"
    title="ยืนยันข้อความ"
    centered
  >
    <div class="list-reply ">
      <div class="panel-warning">
        <font-awesome-icon
          class="icon-exclamation"
          :icon="['fas', 'exclamation-triangle']"
        />
        <p>ยืนยันการใช้ข้อความนี้</p>
      </div>
      <b-list-group-item
        v-if="choice"
        class="list-item-reply"
        :key="`reply-${choice.id}`"
      >
        <div class="d-flex flex-row">
          <div class="d-flex flex-row w-100">
            <div class="w-25 reply-key">
              <p class="font-weight-bold">/{{ choice.shortkeys }}</p>
            </div>
            <div class="w-75 pl-2">
              <b-badge pill variant="badge-public" v-if="choice.type == 1"
                >Public</b-badge
              >
              <b-badge pill variant="badge-private" v-else-if="choice.type == 2"
                >Private</b-badge
              >
              <p class="mb-0">{{ choice.title }}</p>
              <div
                class="div-textReply text-wrap text-black-50"
                v-if="choice.replyType.indexOf(1) > -1"
              >
                {{ choice.textReply }}
              </div>
              <div v-if="choice.replyType.indexOf(2) > -1">
                <div class="w-25" v-if="choice.imageReply">
                  <PreviewFile :urlPath="choice.imageReply" />
                </div>
              </div>
              <div
                class="div-textReply text-wrap text-black-50"
                v-if="choice.replyType.indexOf(4) > -1"
              >
                {{ choice.linkReply }}
              </div>
            </div>
          </div>
        </div>
      </b-list-group-item>
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button @click="hide" variant="outline-primary" class="mr-1"
            >ยกเลิก</b-button
          >
          <b-button
            @click="confirm"
            variant="main-primary"
            class="ml-1"
            :disabled="isLoading"
            >ยืนยัน</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PreviewFile from "@/components/inputs/PreviewFile";

export default {
  props: {
    userChatInfo: {
      type: Object,
      required: false
    },
    crmDetail: {
      type: Object,
      required: false
    },
    chatRoomId: {
      type: [String],
      required: false
    }
  },
  components: {
    PreviewFile
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      choice: null
    };
  },

  methods: {
    init(choice) {
      this.choice = choice || null;
    },
    show(choice) {
      this.init(choice);
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async confirm() {
      this.$emit("confirm", this.choice);
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.list-reply {
  .text-wrap {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #598e74;
    display: -webkit-box;
    min-height: 1.2rem;
    line-height: 1.2rem;
    max-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.list-item-reply:hover {
  background-color: #ebedef;
  cursor: pointer;
}
.reply-key {
  word-wrap: break-word;
}
.badge-badge-private {
  color: #fff;
  background-color: #832abf;
  border: 1px solid #832abf;
}
.badge-badge-public {
  background-color: #fff;
  color: #832abf;
  border: 1px solid #832abf;
  font-size: 10px;
}
.panel-warning {
  text-align: center;
  svg {
    color: #faab32;
    font-size: 90px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: bold;
  }
}
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
@media (max-width: 767.98px) {
  ::v-deep .btn-main-primary,
  ::v-deep .btn-outline-primary {
    min-width: unset !important;
  }
}
</style>
