<template>
  <b-modal v-model="isShowModal" hide-footer hide-header centered size="xs">
    <div class="body-message text-center">
      <span>{{ text || message }}</span>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: [String]
    }
  },
  data() {
    return {
      isShowModal: false,
      message: ""
    };
  },
  methods: {
    show(message = null) {
      this.message = message;
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.body-message {
  color: white;
  font-size: 12px;
  font-weight: normal;
}
</style>
